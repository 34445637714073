import './App.css';
import CheckLogin from './components/checkLogin';

function App() {
  return (
    <div className="App">
    <CheckLogin/>
    </div>
  );
}

export default App;
