import React, { useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";
import { getLocalStorageValue } from '../util/util';
import '../css/pages.css'
import { AddIcon, RemoveIcon } from "../data/icons";
import Modal from '../components/PlayModal';
import '../css/modal.css'
import AddModal from "../components/AddModal";
import CategoryItem from "../components/CategoryComponent";
import * as Constants from '../data/constants';

const TopPlaylists = (props) => {
    const token = getLocalStorageValue("adminPanelLoginData").token;
    const [categoryList, setCategoryList] = useState([])
    const heading = props.heading
    const disableSubmit = props.disableSubmit
    const setdisableSubmit = props.setdisableSubmit
    

    const getCategory = async () => {
        await axios.get(Constants.HOST+ "/api/get_category/",
            {
                headers: {
                    Authorization: `${token}`,
                },
                params: { type: props.selected }
            }
        )
            .then((response) => {
                console.log(response.data)
                setCategoryList(response.data.result)
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        getCategory()
    }, [disableSubmit])

    const [currentSong, setCurrentSong] = useState(null);

    const audioRef = useRef(null);

    useEffect(() => {
        if (currentSong) {
            if (audioRef.current) {
                audioRef.current.src = `${Constants.HOST}/${currentSong.audio_path}`;
                audioRef.current.play().catch(e => console.error('Error playing audio:', e));
            }
        }
    }, [currentSong]);

    const playSong = (song) => {
        if (currentSong && song.id === currentSong.id) {
            return;
        }
        setCurrentSong(song);
    };

    const closeModal = () => {
        setCurrentSong(null);
        if (audioRef.current) {
            audioRef.current.pause();
        }
    };
    const closeAddModal = () => {
        setModalState({ show: false, type: '', categoryId: null, categoryName: '', reqd: [] });
    }
    const [modalState, setModalState] = useState({ show: false, type: '', categoryId: null, categoryName: '', reqd: [] });

    const openAddCategoryModal = () => {
        setModalState({ show: true, type: 'Category', categoryId: props.selected, categoryName: heading, reqd: ['name', 'image'] });
    };

    const openAddSongModal = (categoryId, categoryName) => {
        setModalState({ show: true, type: 'Song', categoryId: categoryId, categoryName: categoryName, reqd: ['name', 'song'] });
    };



    return <div className="type">
        <div className="categoryTitle">
            <div>{heading}</div>
            <div onClick={openAddCategoryModal}><AddIcon /></div>
        </div>
        <CategoryItem
            playSong={playSong}
            categoryList={categoryList}
            openAddSongModal={openAddSongModal}
            getCategory={getCategory}
        />

        {currentSong && (
            <Modal isOpen={!!currentSong} onClose={closeModal}>
                <h2>{currentSong.name}</h2>
                <audio ref={audioRef} controls autoPlay>
                    Your browser does not support the audio element.
                </audio>
            </Modal>
        )}
        {modalState.show && (
            <AddModal isOpen={modalState.show} onClose={closeAddModal} type={modalState.type} category={modalState.categoryId} categoryName={modalState.categoryName} reqd={modalState.reqd} disableSubmit={disableSubmit} setdisableSubmit={setdisableSubmit} />
        )}
    </div>
}

export default TopPlaylists;
