import React, { useRef, useState } from "react";
import axios from "axios";
import { getLocalStorageValue } from '../util/util';
import '../css/pages.css'
import '../css/modal.css'
import '../css/login.css'
import NameComponent from './NameComponent';
import DescriptionComponent from './DescriptionComponent';
import ImageComponent from './ImageComponent';
import { UploadIcon, AddCategoryIcon } from "../data/icons";
import { CircularProgress} from '@mui/material';
import * as Constants from '../data/constants';

const EditModal = ({ type, isOpen, onClose, story, reqd,disableSubmit,setdisableSubmit}) => {
    const token = getLocalStorageValue("adminPanelLoginData").token;

    const [name, setName] = useState(story.name);
    const [desc,setDesc] = useState(story.description);
    const imageInputRef = useRef(null);
    const [image, setImage] = useState();
    const [song, setSong] = useState();
    const [imageName, setimageName] = useState('');
    const [hostname, setHostName] = useState(story.host);
    const [genre, setGenres] = useState(story.genre);


    const handleFileInput = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImage(file)
            setimageName(file.name);
        }
    };

    const handleUploadClick = (event) => {
        event.preventDefault();
        imageInputRef.current.click();
    };

    const onEditStory=async(event)=>{
        event.preventDefault();
        setdisableSubmit(true);
        const formData = new FormData()
        formData.append("image_path", image)
        formData.append("id", story.id)
        formData.append("name",name)
        formData.append("description",desc)
        await axios(
            {
                method: "PATCH",
                url: Constants.HOST + "/api/stories/",
                headers: {
                    Authorization: `${token}`,
                    'Content-Type': 'multipart/form-data'
                },
                data: formData
            }
        )
            .then((response) => {
                setdisableSubmit(false)
                onClose()
                console.log(response.data)
            })
            .catch((err) => {
                setdisableSubmit(false)
                onClose()
                console.log(err);
            })
    }

    const onEditPodcast=async(event)=>{
        event.preventDefault();
        setdisableSubmit(true);
        const formData = new FormData()
        formData.append("image_path", image)
        formData.append("id", story.id)
        formData.append("name",name)
        formData.append("description",desc)
        formData.append("hostname",hostname)
        formData.append("genre",genre)
        await axios(
            {
                method: "PATCH",
                url: Constants.HOST + "/api/podcast/",
                headers: {
                    Authorization: `${token}`,
                    'Content-Type': 'multipart/form-data'
                },
                data: formData
            }
        )
            .then((response) => {
                setdisableSubmit(false)
                onClose()
                console.log(response.data)
            })
            .catch((err) => {
                setdisableSubmit(false)
                onClose()
                console.log(err);
            })
    }

  
    if (!isOpen) return null;

    return (<div className="modal-overlay" onClick={onClose}>
        <div className="modal-content" style={{ backgroundColor: '#0c0a0a', }} onClick={e => e.stopPropagation()}>
            <div>
                <button className="modal-close-button" onClick={onClose}>&times;</button>
            </div>
            <div style={{ padding: '2rem' }}>
                <div style={{ fontSize: '28px', fontWeight: '700' }}> {`Edit ${story.name}`} </div>
            </div>

            <form style={{ width: '100%', display: "flex", justifyContent: 'flex-start', flexDirection: 'column' }} onSubmit={type=='podcast' ? onEditPodcast:onEditStory}>
                {reqd.includes('name') &&
                    <NameComponent name={name} setName={setName} type={'Edit Story'}/>
                }
                {reqd.includes('host') &&
                    <NameComponent name={hostname} setName={setHostName} type={'host'}/>
                }
                {reqd.includes('image') &&
                    <ImageComponent fileInputRef={imageInputRef} fileName={imageName} handleFileInput={handleFileInput} handleUploadClick={handleUploadClick} type={type}/>
                }
                {reqd.includes('description') &&
                    <DescriptionComponent desc={desc} setDesc={setDesc} type={type}/>
                }
                {reqd.includes('genre') &&
                    <DescriptionComponent desc={genre} setDesc={setGenres} type={'genre'}/>
                }
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '5px' }}>
                    <button className="cancelButton" onClick={onClose}>
                        <div>Cancel</div>
                    </button>
                    <button className="submitButton1" type="submit" disabled={name === "" || desc===""}>
                        
                        {disableSubmit && <CircularProgress sx={{ color: 'white' }} size={12} />}
                        {!disableSubmit && 
                        <div style={{ display: 'flex', gap: '10px' }}>
                        <div>Edit</div>
                        <AddCategoryIcon />
                        </div>}
                    </button>
                </div>
            </form>
        </div>
    </div>)

}

export default EditModal;