import axios from "axios";
import React, { useEffect, useState } from "react";
import TopBar from "../components/TopBar";
import DailyMood from "./DailyMood";
import TopPlaylists from "./TopPlaylists";
import SleepStories from "./SleepStories";
import DifferentSounds from "./DifferentSounds";
import SoundEffects from "./SoundEffects";
import Podcast from "./Podcast";
import Frequencies from "./Frequencies";
import BianuralBeats from "./BianuralBeats";

const AdminPage = () => {

    const [selected, setSelected] = useState(1)
    const [heading,setHeading]=useState('Daily Mood')
    const [disableSubmit, setdisableSubmit] = useState();
    const pages = {
        DailyMood,
        TopPlaylists,
        SleepStories,
        DifferentSounds,
        SoundEffects,
        Frequencies,
        Podcast,
        BianuralBeats
    };
    
    const typeMapping = [
        { 'value': 1, 'label': 'Daily Mood' },
        { 'value': 2, 'label': 'Top Playlists' },
        { 'value': 3, 'label': 'Sleep Stories' },
        { 'value': 4, 'label': 'Different Sounds' },
        { 'value': 5, 'label': 'Sound Effects' },
        { 'value': 6, 'label': 'Frequencies'},
        { 'value': 7, 'label': 'Podcasts'},
        { 'value': 8, 'label': 'Bianural Beats'},
    ]

    const [selectedPage, setSelectedPage] = useState('DailyMood');

    useEffect(() => {
        handlePage()
    }, [selected])

    const handlePage = () => {
        const pageKeys = {
            1: 'DailyMood',
            2: 'TopPlaylists',
            3: 'SleepStories',
            4: 'DifferentSounds',
            5: 'SoundEffects',
            6: 'Frequencies',
            7: 'Podcast',
            8: 'BianuralBeats'
        };
        setSelectedPage(pageKeys[selected] || 'DailyMood');
    }


    const handleChange = (val) => {
        setSelected(val.value)
        setHeading(val.label)
    }
    const PageComponent = pages[selectedPage];

    return <div>
        <TopBar
            selected={selected}
            typeMapping={typeMapping}
            handleChange={handleChange} />
        <PageComponent selected={selected} heading={heading} disableSubmit={disableSubmit} setdisableSubmit={setdisableSubmit}/>
    </div>
}

export default AdminPage;