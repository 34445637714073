import React, { useState } from "react";
import "../css/login.css";
import TextField from "@mui/material/TextField";
import loginDoodle from "../img/loginDoodle.png";
import { CircularProgress } from "@mui/material";
import { setLocalStorageValue, capitalize } from "../util/util";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { InputAdornment } from "@mui/material";
import { IconButton } from "@mui/material";
import axios from "axios";
import * as Constants from "../data/constants";

export default function Login(props) {
  document.title = "Login - Admin";
  const [error, setError] = React.useState({ data: "", status: "" });
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [disableLoginButton, setDisableLoginButton] = useState();
  const [showPassword, setShowPassword] = useState(false);

  async function loginUser(credentials) {
    setDisableLoginButton(true);
    return await axios({
      method: "POST",
      url: "".concat(Constants.HOST, "/api/login/"),
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        username: credentials.username,
        password: credentials.password,
      },
    })
      .then(async (resp) => {
        return resp.data;
      })
      .catch((error) => {
        setDisableLoginButton(false);
        if (error.response) {
          console.log(error);
          setError({
            data: error.response.data,
            status: error.response.status,
          });
        } else if (error.request) {
          console.log(error.request);
        } else {
          setError({ data: error.message, status: error.status });
          console.log("Error", error.message);
        }
        console.error(error);
      });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    var response = await loginUser({
      username,
      password,
    });
    if (response) {
      var userDetails = {
        token: "Token " + response.token,
        superuser: response.user.is_superuser,
        loginTime: new Date().getTime(),
        userdata: response.user,
      };
      console.log("hello");
      setLocalStorageValue("adminPanelLoginData", userDetails);
      console.log(userDetails);
      localStorage.setItem("adminloginTime", new Date().getTime());
      window.location = "/dashboard";
    }
    setDisableLoginButton(false);
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className="loginPage">
      <div className="mainBox">
        <div className="leftSideWrapper">
          <div
            className="leftSideDetails"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={loginDoodle}
              style={{ height: "100%", width: "100%" }}
              alt=""
              className="loginDoodle"
            />
          </div>
        </div>
        <div className="rightSideWrapper">
          <div className="login-wrapper">
            <div className="loginLabel">Welcome to Soundscape</div>
            <div className="loginContent">
              {" "}
              Your Personal Oasis of Serenity!{" "}
            </div>
            <form
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "column",
                width: "100%",
                paddingTop: "50px",
              }}
              onSubmit={handleSubmit}
            >
              <div className="labelStyle">Email ID</div>
              <div className="emailTextBox">
                <TextField
                  fullWidth
                  required
                  id="outlined-basic"
                  placeholder="Type here..."
                  variant="outlined"
                  // color="warning"
                  size="normal"
                  sx={{
                    transform: "scale(1.0)",
                    "& .MuiOutlinedInput-root": {
                      color: "#FFF",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#F3F3F3",
                        borderWidth: "2px",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#F3F3F3",
                      },
                      "& input": {
                        WebkitBoxShadow:
                          "0 0 0 1000px transparent inset !important",
                        WebkitTextFillColor: "#FFF !important",
                        caretColor: "#FFF !important",
                      },
                      "& input:-webkit-autofill": {
                        WebkitBoxShadow:
                          "0 0 0 1000px transparent inset !important",
                        WebkitTextFillColor: "#FFF !important",
                        transition: "background-color 5000s ease-in-out 0s", // Delay transition to ensure the color change is not visible
                      },
                      "& input:-webkit-autofill:hover, & input:-webkit-autofill:focus, & input:-webkit-autofill:active":
                        {
                          WebkitBoxShadow:
                            "0 0 0 1000px transparent inset !important",
                        },
                    },
                  }}
                  onChange={(e) => setUserName(e.target.value)}
                  InputProps={{
                    style: {
                      borderRadius: "40px",
                    },
                  }}
                />
              </div>
              <div className="labelStyle">Password</div>
              <div className="passwordTextBox">
                <TextField
                  fullWidth
                  required
                  id="standard-password-input"
                  placeholder="Type here..."
                  type={showPassword ? "text" : "password"}
                  autoComplete="current-password"
                  variant="outlined"
                  color="warning"
                  sx={{
                    transform: "scale(1.0)",
                    "& .MuiOutlinedInput-root": {
                      color: "#FFF",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#F3F3F3",
                        borderWidth: "2px",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#F3F3F3",
                      },
                      "& input": {
                        WebkitBoxShadow:
                          "0 0 0 1000px transparent inset !important",
                        WebkitTextFillColor: "#FFF !important",
                        caretColor: "#FFF !important",
                      },
                      "& input:-webkit-autofill": {
                        WebkitBoxShadow:
                          "0 0 0 1000px transparent inset !important",
                        WebkitTextFillColor: "#FFF !important",
                        transition: "background-color 5000s ease-in-out 0s", // Delay transition to ensure the color change is not visible
                      },
                      "& input:-webkit-autofill:hover, & input:-webkit-autofill:focus, & input:-webkit-autofill:active":
                        {
                          WebkitBoxShadow:
                            "0 0 0 1000px transparent inset !important",
                        },
                    },
                  }}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    style: {
                      borderRadius: "40px",
                    },
                    endAdornment: (
                      <InputAdornment position="end" color="#F3F3F3">
                        <IconButton
                          sx={{ color: "#F3F3F3" }}
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div
                style={{
                  width: "60%",
                  alignSelf: "flex-end",
                  fontSize: "16px",
                  color: "#F3F3F3",
                  fontWeight: "500",
                }}
              >
                <u> Forgot Password</u>
              </div>
              <div
                style={{ marginTop: "20px", marginBottom: "-20px" }}
                className={error.data !== "" ? "showError" : "hideError"}
              >
                {error.data.detail ? capitalize(error.data.detail) : "."}
              </div>
              <div style={{ width: "100%", marginTop: "3rem" }}>
                <button className="submitButton" type="submit">
                  {disableLoginButton && (
                    <CircularProgress sx={{ color: "white" }} size={12} />
                  )}
                  {!disableLoginButton && (
                    <div
                      style={{
                        fontSize: "1.5rem",
                        fontWeight: "500",
                        color: "#FFFFFF",
                      }}
                    >
                      Continue
                    </div>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

// Login.propTypes = {
// };
