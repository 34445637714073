import React, { useState } from "react";
import appIcon from "../assets/appIcon.svg";
import '../css/topbar.css';
import LogoutIcon from '@mui/icons-material/Logout';
import * as Constants from '../data/constants';
import axios from "axios";
import { getLocalStorageValue,removeLocalStorageValue } from '../util/util';

const TopBar=(props)=>{

    const selected=props.selected

    const typeMapping=props.typeMapping;
    const token = getLocalStorageValue("adminPanelLoginData").token;
    const [openLogoutModal,setIsOpenLogoutModel] = useState(false);

    const onClose=()=>{
        setIsOpenLogoutModel(false);
    }

    const onOpen=()=>{
        setIsOpenLogoutModel(true);
    }

    const ConfirmModal = () => {
        return (
            <div className="modal-overlay" onClick={onClose}>
                <div className="modal-content" onClick={e => e.stopPropagation()}>
                    <div> Are you sure you want to logout?</div>
                    <div style={{ display: "flex", justifyContent: "center", gap: "20px",marginTop:'2rem'}}>
                        <button className="submitButton"
                            style={{
                                width: "5rem",
                                padding: "10px",
                                color: "#FFFFFF",
                                fontSize: "20px",
                                height: "40px",
                                borderRadius: "10px",
                                alignItems: "center",
                                cursor:"pointer"
                            }}
                            onClick={handleLogout}>Yes</button>
                        <button className="submitButton" 
                         style={{
                            width: "5rem",
                            padding: "10px",
                            color: "#FFFFFF",
                            fontSize: "20px",
                            height: "40px",
                            borderRadius: "10px",
                            alignItems: "center",
                            cursor:"pointer"
                        }}
                        onClick={onClose}>No</button>
                    </div>
                </div>
            </div>
        );
    };

    const handleLogout = async()=>{
        await axios(
            {
                method: "POST",
                url: Constants.HOST+ "/api/logout/",
                headers: {
                    Authorization: `${token}`,
                    'Content-Type': 'multipart/form-data'
                },
            }
        )
            .then((response) => {
                localStorage.removeItem('adminPanelLoginData');
                removeLocalStorageValue('adminPanelLoginData');
                sessionStorage.clear();
                window.location.reload();
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return <div className="topBar">
        <div className='title'>
        <img src={appIcon} alt="Some" style={{height:"4rem",width:"4rem"}}/>
        <div> Soundscape</div>
        </div>
        <div className="headingList">
            {typeMapping.map((item,index)=>{
                return <div key={index} style={{fontWeight:selected===item.value?500:200,cursor:'pointer'}}
                onClick={()=>props.handleChange(item)}
                >{item.label}</div>
            })
            }
        </div>
        <div onClick={()=>onOpen()} style={{cursor:'pointer'}}> <LogoutIcon/> </div>
        {openLogoutModal && <ConfirmModal/>}
    </div>
}

export default TopBar;