export const AddIcon=()=>{
    return <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6_33)">
    <path d="M13.2 11.3V6.5H10.8V11.3H6V13.7H10.8V18.5H13.2V13.7H18V11.3H13.2ZM12 24.5C8.8174 24.5 5.76516 23.2357 3.51472 20.9853C1.26428 18.7348 0 15.6826 0 12.5C0 9.3174 1.26428 6.26516 3.51472 4.01472C5.76516 1.76428 8.8174 0.5 12 0.5C15.1826 0.5 18.2348 1.76428 20.4853 4.01472C22.7357 6.26516 24 9.3174 24 12.5C24 15.6826 22.7357 18.7348 20.4853 20.9853C18.2348 23.2357 15.1826 24.5 12 24.5Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_6_33">
    <rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
    </clipPath>
    </defs>
    </svg>    
}

export const RemoveIcon=()=>{
    return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_8_2538)">
    <path d="M10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0ZM5 10C5 10.2273 5.09031 10.4453 5.25105 10.6061C5.4118 10.7668 5.62981 10.8571 5.85714 10.8571H14.1429C14.3702 10.8571 14.5882 10.7668 14.749 10.6061C14.9097 10.4453 15 10.2273 15 10C15 9.77267 14.9097 9.55465 14.749 9.39391C14.5882 9.23316 14.3702 9.14286 14.1429 9.14286H5.85714C5.74458 9.14286 5.63312 9.16503 5.52913 9.2081C5.42514 9.25118 5.33064 9.31432 5.25105 9.39391C5.17146 9.4735 5.10832 9.56799 5.06525 9.67199C5.02217 9.77598 5 9.88744 5 10Z" fill="#F21616"/>
    </g>
    <defs>
    <clipPath id="clip0_8_2538">
    <rect width="20" height="20" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    
}

export const UploadIcon=()=>{
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 17V19C4 19.5304 4.21071 20.0391 4.58579 20.4142C4.96086 20.7893 5.46957 21 6 21H18C18.5304 21 19.0391 20.7893 19.4142 20.4142C19.7893 20.0391 20 19.5304 20 19V17M7 9L12 4M12 4L17 9M12 4V16" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
}

export const AddCategoryIcon=()=>{
    return  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17 9.50195C19.175 9.51395 20.353 9.61095 21.121 10.379C22 11.258 22 12.672 22 15.5V16.5C22 19.329 22 20.743 21.121 21.622C20.243 22.5 18.828 22.5 16 22.5H8C5.172 22.5 3.757 22.5 2.879 21.622C2 20.742 2 19.329 2 16.5V15.5C2 12.672 2 11.258 2.879 10.379C3.647 9.61095 4.825 9.51395 7 9.50195" stroke="black" stroke-width="1.5" stroke-linecap="round"/>
    <path d="M12 15.5V2.5M12 2.5L15 6M12 2.5L9 6" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
}