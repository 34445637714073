import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import React, { useState,useRef } from "react";
import { AddIcon, RemoveIcon } from "../data/icons";
import axios from "axios";
import { getLocalStorageValue } from '../util/util'
import * as Constants from '../data/constants';
import ConfirmModal from "./ConfirmDialog";

const CategoryItem = ({ categoryList, openAddSongModal, playSong,getCategory}) => {
    const token = getLocalStorageValue("adminPanelLoginData").token;
    const [quote, setQuote] = useState('');
    const [hoveredCategoryId, setHoveredCategoryId] = useState(null);
    const [clickedCategoryId, setClickedCategoryId] = useState(null);
    const fileInputRef = useRef(null);
    const [openConfirm, setOpenConfirm] = useState(false)

    const [toBeDeleted, setToBeDeleted] = useState({ type: '', cat_id: '', song_id: '' });

    const handleDeleteSongClick = (cat_id, song_id) => {
        setToBeDeleted({ type: 'song', cat_id: cat_id, song_id: song_id });
        setOpenConfirm(true);
    };

    const handleDeleteCategoryClick = (cat_id) => {
        setToBeDeleted({ type: 'category', cat_id: cat_id, song_id: '' });
        setOpenConfirm(true);
    };

    const closeDialog = () => {
        setOpenConfirm(false);
    };

    const deleteCategory = async () => {
        await axios(
            {
                method: "DELETE",
                url: Constants.HOST+ `/api/category/`,
                headers: {
                    Authorization: `${token}`
                },
                params: { category_id: toBeDeleted.cat_id }
            }
        )
            .then((response) => {
                console.log(response.data)
                getCategory()
                setOpenConfirm(false);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const deleteSong = async () => {
        await axios(
            {
                method: "DELETE",
                url: Constants.HOST+ `/api/audios/`,
                headers: {
                    Authorization: `${token}`
                },
                params: { category_id: toBeDeleted.cat_id, audio_id: toBeDeleted.song_id }
            }
        )
            .then((response) => {
                console.log(response.data)
                getCategory()
                setOpenConfirm(false);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const handleQuoteEdit = (item, quote) => {
        setClickedCategoryId(item.id)
        setQuote(item.quote)
    }

    const SaveQuoteEdit = () => {
        const formData = new FormData();
        formData.append("quote", quote);
        formData.append("category_id", clickedCategoryId);
        handleCategoryEdit(formData);
    }

    const handleCategoryEdit = async (formData) => {
        try {
            const response = await axios({
                method: "PATCH",
                url: Constants.HOST+ "/api/category/",
                headers: {
                    Authorization: `${token}`,
                    'Content-Type': 'multipart/form-data'
                },
                data: formData
            });
            console.log("Category updated:", response.data);
            setClickedCategoryId(null)
            getCategory();
        } catch (err) {
            console.error("Failed to update category:", err);
        }
    }

    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append("image_path", file);
            formData.append("category_id", hoveredCategoryId);
            handleCategoryEdit(formData);
        }
    };
    const handleChangedStatus = async (song_id,category_id) => {
        try {
            const response = await axios.put(
                `${Constants.HOST}/api/audios/`,
                { status: true }, // Set the status to true
                {
                    headers: {
                        Authorization: `${token}`,
                    },
                    params: {
                        audio_id: song_id,
                        category_id:category_id ,
                    },
                }
            );
            console.log(response.data);
            // Optionally, you can update the local state or refetch the category list
            getCategory();
        } catch (error) {
            console.error("Failed to update song status:", error);
        }
    };

    return (<div>
        <div className="categoryList">
            {categoryList.map((item) => (
                <div className="categoryItem">
                    <div style={{ display: 'flex', gap: '5px', alignItems: 'center', width: '100%' }}>
                        <div className="subcategoryTitle">
                            <div className="imageContainer" onMouseEnter={() => setHoveredCategoryId(item.id)}
                                onMouseLeave={() => setHoveredCategoryId(null)}>
                                <img
                                    src={`${Constants.HOST}/${item.image_path}`}
                                    alt={item.name}
                                    className="categoryImage"
                                />
                                {hoveredCategoryId === item.id && (
                                    <div className="editIcon" onClick={() => fileInputRef.current.click()}>
                                        <EditIcon />
                                    </div>
                                )}
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    placehodler={'Add Quote...'}
                                    style={{ display: 'none' }}
                                    onChange={handleFileSelect}
                                    accept="image/*"
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', justifyContent: 'center', width: '100%' }}>
                            <div className="subcategoryTitle">
                                {item.name}
                                <div onClick={() => openAddSongModal(item.id, item.name)}>
                                    <AddIcon />
                                </div>
                                <div onClick={() => handleDeleteCategoryClick(item.id)}>
                                    <RemoveIcon />
                                </div>
                            </div>
                            <div className="songContent">
                                {clickedCategoryId === item.id ? (
                                    <>
                                        <input
                                            type="text"
                                            value={quote}
                                            onChange={e => setQuote(e.target.value)}
                                            style={{
                                                borderRadius: "5px",
                                                background: "transparent",
                                                color: "#FFFFFF",
                                                border: "1px solid #F3F3F3",
                                                width: '50%',
                                                padding: '5px'
                                            }}
                                        />
                                        <div className="editText" onClick={() => SaveQuoteEdit(item)}>
                                            <CheckIcon />
                                        </div>
                                    </>) : ((
                                        <>
                                            {item.quote}
                                            <div className="editText" onClick={() => handleQuoteEdit(item)}>
                                                <EditIcon />
                                            </div>
                                        </>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className="songGrid">
                        {item.songs && item.songs.map((song, index) => (
                            <div key={index} className="songItem">
                                <div className="songTitle">
                                    {(song.status===false)?(
                                        <div onClick={() => handleChangedStatus(song.id,item.id)}>
                                            <AddIcon />
                                        </div>
                                    ):(
                                    <div onClick={() => handleDeleteSongClick(item.id, song.id)}>
                                        <RemoveIcon />
                                    </div>
                                    )}
                                    <div onClick={() => playSong(song)} style={{display:'flex',gap:'20px'}}>
                                    <div> {song.name} </div>
                                    <div> {`(${song.duration})`} </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
        {openConfirm &&
            <ConfirmModal
                isOpen={openConfirm}
                onClose={closeDialog}
                onConfirm={toBeDeleted.type === "song" ? deleteSong : deleteCategory}
                type={toBeDeleted.type}>
            </ConfirmModal>
        }
        </div>
        )
}

export default CategoryItem;