import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { getLocalStorageValue } from '../util/util';
import '../css/pages.css'
import '../css/frequencyCard.css'
import { AddIcon, RemoveIcon } from "../data/icons";
import '../css/modal.css'
import AddModal from "../components/AddModal";
import Modal from '../components/PlayModal';
import * as Constants from '../data/constants';
import ConfirmModal from "../components/ConfirmDialog";
import InfoIcon from '@mui/icons-material/Info';
import FrequencyInfoModal from "../components/brainwaves/FrequencyInfoModal";
import EditIcon from '@mui/icons-material/Edit';
import { CircularProgress } from '@mui/material';
import EditFrequencyModal from "../components/brainwaves/EditFrequencyModal";

const Frequencies = (props) => {

    const token = getLocalStorageValue("adminPanelLoginData").token;
    const [categoryList, setCategoryList] = useState([])
    const heading = props.heading
    const disableSubmit = props.disableSubmit
    const setdisableSubmit = props.setdisableSubmit
    const [frequencyInfo, setFrequencyInfo] = useState()
    const [openConfirm, setOpenConfirm] = useState(false)
    const [openEditModal, setOpenEdit] = useState(false)
    const getCategory = async () => {
        await axios.get(Constants.HOST + "/api/get_category/",
            {
                headers: {
                    Authorization: `${token}`,
                },
                params: { type: props.selected }
            }
        )
            .then((response) => {
                setCategoryList(response.data.result)
                if (response.data.result.length > 0) {
                    var freqData = {}
                    const data = response.data.result[0];
                    freqData['description'] = data['quote'];
                    freqData['image_path'] = data['image_path'];
                    freqData['color_codes'] = data['color_codes'];
                    setFrequencyInfo(freqData)
                    console.log(freqData)
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        getCategory()
    }, [disableSubmit])

    const [currentSong, setCurrentSong] = useState(null);

    const audioRef = useRef(null);

    useEffect(() => {
        if (currentSong) {
            if (audioRef.current) {
                audioRef.current.src = `${Constants.HOST}/${currentSong.audio_path}`;
                audioRef.current.play().catch(e => console.error('Error playing audio:', e));
            }
        }
    }, [currentSong]);

    const playSong = (song) => {
        console.log(currentSong)
        if (currentSong && song.id === currentSong.id) {
            return;
        }
        setCurrentSong(song);
    };

    const closeModal = () => {
        setCurrentSong(null);
        if (audioRef.current) {
            audioRef.current.pause();
        }
    };
    const [openInfoModal, setOpenInfoModal] = useState(false);
    const [modalState, setModalState] = useState({ show: false, type: '', categoryId: null, categoryName: '', reqd: [] });

    const closeAddModal = () => {
        setModalState({ show: false, type: '', categoryId: null, categoryName: '', reqd: [] });
    }

    const openAddCategoryModal = () => {
        setModalState({ show: true, type: 'Category', categoryId: props.selected, categoryName: heading, reqd: ['name'] });
    };

    const openAddSongModal = (categoryId, categoryName) => {
        setModalState({ show: true, type: 'Song', categoryId: categoryId, categoryName: categoryName, reqd: ['name', 'value', 'color_codes', 'image', 'song'] });
    };

    const [toBeDeleted, setToBeDeleted] = useState({ type: '', cat_id: '', song_id: '' });

    const handleDeleteSongClick = (cat_id, song_id) => {
        setToBeDeleted({ type: 'song', cat_id: cat_id, song_id: song_id });
        setOpenConfirm(true);
    };

    const handleDeleteCategoryClick = (cat_id) => {
        setToBeDeleted({ type: 'category', cat_id: cat_id, song_id: '' });
        setOpenConfirm(true);
    };

    const deleteCategory = async () => {
        await axios(
            {
                method: "DELETE",
                url: Constants.HOST + '/api/category/',
                headers: {
                    Authorization: `${token}`
                },
                params: { category_id: toBeDeleted.cat_id }
            }
        )
            .then((response) => {
                console.log(response.data)
                getCategory()
                setOpenConfirm(false);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const deleteSong = async () => {
        await axios(
            {
                method: "DELETE",
                url: Constants.HOST + `/api/audios/`,
                headers: {
                    Authorization: `${token}`
                },
                params: { category_id: toBeDeleted.cat_id, audio_id: toBeDeleted.song_id }
            }
        )
            .then((response) => {
                console.log(response.data)
                getCategory()
                setOpenConfirm(false);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const closeDialog = () => {
        setOpenConfirm(false);
    };

    const handleChangedStatus = async (song_id, category_id) => {
        try {
            const response = await axios.put(
                `${Constants.HOST}/api/audios/`,
                { status: true }, // Set the status to true
                {
                    headers: {
                        Authorization: `${token}`,
                    },
                    params: {
                        audio_id: song_id,
                        category_id: category_id,
                    },
                }
            );
            console.log(response.data);
            // Optionally, you can update the local state or refetch the category list
            getCategory();
        } catch (error) {
            console.error("Failed to update song status:", error);
        }
    };


    const CategoryItem = () => (
        <div className="categoryList">
            {categoryList.map((item) => (
                <div className="categoryItem">
                    <div key={item.id} className="subcategoryTitle">
                        {item.name}
                        <div onClick={() => openAddSongModal(item.id, item.name)}>
                            <AddIcon />
                        </div>
                        <div onClick={() => handleDeleteCategoryClick(item.id)}>
                            <RemoveIcon />
                        </div>
                    </div>
                    <div className="songGrid">
                        {item.songs && item.songs.map((song, index) => {
                            var colorList = song.color_codes.map((code) => '#' + code);
                            var colors = colorList.join(', ')
                            return <div className='container' style={{ opacity: song.status ? 1 : 0.5 }} onClick={() => song.status && playSong(song)}>
                                <div className='bottomContainer' style={{ backgroundImage: `linear-gradient(45deg, ${colors})` }} >
                                    <div className='name'>
                                        {song.name || ""}
                                    </div>
                                </div>
                                <div className='topContainer'>
                                    <div className="freqImageContainer" >
                                        <img src={`${Constants.HOST}/${song.image_path}`}
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover'
                                            }} />
                                    </div>
                                    <div className='value'>{song.value || ""} </div>
                                    {(song.status === false) ? (
                                        <div className="removeFreqContainer" onClick={() => handleChangedStatus(song.id, item.id)}>
                                            <AddIcon />
                                        </div>
                                    ) : (
                                        <div className="removeFreqContainer" onClick={() => handleDeleteSongClick(item.id, song.id)}>
                                            <RemoveIcon />
                                        </div>
                                    )}
                                </div>
                            </div>
                        }
                        )}
                    </div>
                </div>
            ))}
        </div>
    );


    return <div className="type">
        <div className="categoryTitle">
            <div>{heading}</div>
            <div onClick={openAddCategoryModal}><AddIcon /></div>
            <div onClick={setOpenInfoModal}><InfoIcon/></div>
        </div>
        <CategoryItem />
        {currentSong && (
            <Modal isOpen={!!currentSong} onClose={closeModal}>
                <h2>{currentSong.name}</h2>
                <h3>({currentSong.value})</h3>
                <audio ref={audioRef} controls autoPlay>
                    Your browser does not support the audio element.
                </audio>
                <div className="button-container">
                    <button className="submitButton1" type="button" style={{ borderRadius: '30px', backgroundColor: '#A5A5A5' }}>
                        {disableSubmit && <CircularProgress sx={{ color: 'white' }} size={12} />}
                        {!disableSubmit &&
                            <div style={{ display: 'flex', gap: '10px' }} onClick={() => { setOpenEdit(true); audioRef.current.pause(); }}>
                                <EditIcon />
                            </div>}
                    </button>
                </div>
            </Modal>
        )}

        {modalState.show && (
            <AddModal isOpen={modalState.show} onClose={closeAddModal} type={modalState.type} category={modalState.categoryId} categoryName={modalState.categoryName} reqd={modalState.reqd} disableSubmit={disableSubmit} setdisableSubmit={setdisableSubmit} />
        )}
        {openConfirm &&
            <ConfirmModal
                isOpen={openConfirm}
                onClose={closeDialog}
                onConfirm={toBeDeleted.type === "song" ? deleteSong : deleteCategory}
                type={toBeDeleted.type}>
            </ConfirmModal>
        }
        {openInfoModal &&
            <FrequencyInfoModal isOpen={openInfoModal} onClose={() => setOpenInfoModal(false)} frequencyInfo={frequencyInfo} getCategory={getCategory} />
        }
        {openEditModal &&
            <EditFrequencyModal isOpen={openEditModal} onClose={() => {setOpenEdit(false);setCurrentSong(null)}} song={currentSong} disableSubmit={disableSubmit} setdisableSubmit={setdisableSubmit} type={'Song'} reqd={['name', 'value', 'image', 'color_codes', 'song']} />
        }
    </div>
}

export default Frequencies