import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { getLocalStorageValue } from '../util/util';
import '../css/pages.css'
import { AddIcon, RemoveIcon } from "../data/icons";
import '../css/modal.css'
import AddModal from "../components/AddModal";
import EditIcon from '@mui/icons-material/Edit';
import EditModal from "../components/EditModal";
import * as Constants from '../data/constants';
import ConfirmModal from "../components/ConfirmDialog";
import PodEpisodeModal from '../components/podcast/PodEpisodeModal';
import EditEpisodeModal from "../components/podcast/EditEpisodeModal";

const Podcast = (props) => {
    const token = getLocalStorageValue("adminPanelLoginData").token;
    const [podcastList, setPodcastList] = useState([])
    const heading = props.heading
    const disableSubmit = props.disableSubmit
    const setdisableSubmit = props.setdisableSubmit
    const [openConfirm, setOpenConfirm] = useState(false)
    const getPodcasts = async () => {
        await axios.get(Constants.HOST + "/api/get_podcasts/",
            {
                headers: {
                    Authorization: `${token}`,
                },
            }
        )
            .then((response) => {
                setPodcastList(response.data.result)
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        getPodcasts()
    }, [disableSubmit])

    const [modalState, setModalState] = useState({ show: false, type: '', categoryId: null, categoryName: '', reqd: [] });

    const closeAddModal = () => {
        setModalState({ show: false, type: '', categoryId: null, categoryName: '', reqd: [] });
        setCurrentPodcast(null)
    }

    const [editPodcast, setEditPodcast] = useState(null);
    const [currentPodcast, setCurrentPodcast] = useState(null)

    const playPodcast = (podcast) => {
        if (currentPodcast && podcast.id === currentPodcast.id) {
            return;
        }
        setCurrentPodcast(podcast);
    };
    const closeDialog = () => {
        setOpenConfirm(false);
        setCurrentPodcast(null);
    };
    const closeModal = () => {
        setCurrentPodcast(null);
        getPodcasts()
    };
    const closeEditModal = () => {
        setEditPodcast(null);
        getPodcasts()
    };

    const openAddEpisodeModal = (podcastId, podcastName) => {
        setModalState({ show: true, type: 'Podcast Episode', categoryId: podcastId, categoryName: podcastName, reqd: ['name', 'episodeNo','image', 'song', 'description'] });
    };

    const [editEpisode,setEditEpisode] = useState(null)
    const onEditEpisode = (episode) =>{
        setEditEpisode(episode)
        setCurrentPodcast(null)
    }

    const onCloseEditEpisode = () =>{
        setEditEpisode(null)
    }

    const openAddPodcastModal = () => {
        setModalState({ show: true, type: 'Podcast', reqd: ['name', 'image', 'description', 'host', 'genre'] });
    };


    const openEditModal = (podcast) => {
        setCurrentPodcast(null);
        setEditPodcast(podcast)
    }
    const handleChangedStatus = async (podcast_id) => {
        try {
            const response = await axios.put(
                `${Constants.HOST}/api/podcast/`,
                { status: true },
                {
                    headers: {
                        Authorization: `${token}`,
                    },
                    params: {
                        podcast_id: podcast_id,
                    },
                }
            );
            console.log(response.data);
            getPodcasts();
            setOpenConfirm(false)
            setCurrentPodcast(null)
        } catch (error) {
            console.error("Failed to update story status:", error);
        }
    }
    const [toBeDeleted, setToBeDeleted] = useState({ podcast_id: '' });
    const handleDeletePodcastClick = (podcast_id) => {
        setOpenConfirm(true);
        setToBeDeleted({ 'podcast_id': podcast_id })
    };

    const PodcastCard = () => {
        return <div>
            <div className="songGrid">
                {podcastList.map((podcast, index) => {
                    return <div key={index} className="songItem">
                        <div className="storyContainer" onClick={() => podcast.status && playPodcast(podcast)}  style={{ opacity: podcast.status ? 1 : 0.5 }}>
                            {(podcast.status === false) ? (
                                <div className="removeContainer" onClick={() => handleChangedStatus(podcast.id)} style={{top:'-10px',right:'16px'}}>
                                    <AddIcon />
                                </div>
                            ) : (
                                <div className="removeContainer" onClick={() => handleDeletePodcastClick(podcast.id)} style={{top:'-10px',right:'16px'}}>
                                    <RemoveIcon />
                                </div>
                            )}
                            <div className="imagePodcast">
                                <div className="imageContainer">
                                    <img
                                        src={`${Constants.HOST}/${podcast.image_path}`}
                                        alt={podcast.name}
                                        height='80%'
                                        width='80%'
                                    />
                                </div>
                                <div className="podcastDetail">
                                    <div className='genres'>{podcast.genre}</div>
                                    <div className="podcastTitle">{podcast.name}</div>
                                    <div className="hostname">{podcast.host}</div>
                                </div>

                            </div>
                        </div>
                    </div>
                })

                }
            </div>
        </div>
    }

    return <div className="type">
        <div className="categoryTitle">
            <div>{heading}</div>
            <div onClick={openAddPodcastModal}><AddIcon /></div>
        </div>
        <PodcastCard />
        {modalState.show && (
            <AddModal isOpen={modalState.show} onClose={closeAddModal} type={modalState.type} category={modalState.categoryId} categoryName={modalState.categoryName} reqd={modalState.reqd} disableSubmit={disableSubmit} setdisableSubmit={setdisableSubmit} />
        )}
        {editPodcast && (
            <EditModal type={'podcast'} isOpen={!!editPodcast} onClose={closeEditModal} story={editPodcast} reqd={['name', 'image', 'description','host','genre']} disableSubmit={disableSubmit} setdisableSubmit={setdisableSubmit} ></EditModal>
        )}
        {!openConfirm && currentPodcast && (
            <PodEpisodeModal isOpen={!!currentPodcast} onClose={closeModal} podcastData= {currentPodcast} openAddEpisodeModal={openAddEpisodeModal} openEditModal={openEditModal} onEditEpisode={onEditEpisode}>
            </PodEpisodeModal>
        )}
        {openConfirm &&
            <ConfirmModal
                isOpen={openConfirm}
                onClose={closeDialog}
                onConfirm={() => handleChangedStatus(toBeDeleted.podcast_id)}
                type={'Podcast'}>
            </ConfirmModal>
        }
        {editEpisode && 
        <EditEpisodeModal isOpen= {!!editEpisode} onClose={onCloseEditEpisode} episode={editEpisode} disableSubmit={disableSubmit} setdisableSubmit={setdisableSubmit} reqd={['name','episode_no','image','song','description']} type={'Epsiode'}/>
        }
    </div>
}

export default Podcast