import React from "react";
import { UploadIcon } from "../data/icons";
import previewImage from "../assets/previewImage.svg";


const ImageComponent = ({ fileInputRef, fileName, handleFileInput, handleUploadClick,type }) => {
    return <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'flex', fontSize: '18px', fontWeight: '400', padding: '1rem' }}>{type==='Story'?'New Image':'Add Image'}</div>
            <button onClick={handleUploadClick} style={{ background: 'none', border: 'none', cursor: 'pointer', padding: '0' }}>
                <UploadIcon />
            </button>
            <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileInput}
                style={{ display: 'none' }}
                accept="image/*"
                required={false}
            />
        </div>
        <img src={previewImage} style={{ height: "2.5rem", width: "2.5rem", paddingLeft: '1rem' }} />
        <div style={{ display: "flex", paddingLeft: '1rem', color: '#ACAAAA' }}>{fileName === '' ? 'No file chosen' : fileName}</div>
    </div>
}

export default ImageComponent;