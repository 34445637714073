import React from 'react';
import TextField from '@mui/material/TextField';


const ValueComponent = ({ type, value, setValue }) => {
    return (<div>
        <div style={{ display: 'flex', fontSize: '18px', fontWeight: '400', padding: '1rem' }}>{'Value'}</div>
        <div style={{ display: 'flex', padding: '0.5rem' }}>
            <TextField
                key="newFrequency"
                fullWidth
                required
                id="outlined-basic"
                placeholder="Type here..."
                variant="outlined"
                // color="warning"
                size="normal"
                sx={{
                    "& .MuiOutlinedInput-root": {
                        color: "#FFF",
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#F3F3F3",
                            borderWidth: "2px"
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#F3F3F3",
                        },
                        "& input": {
                            WebkitBoxShadow: "0 0 0 1000px transparent inset !important",
                            WebkitTextFillColor: "#FFF !important",
                            caretColor: '#FFF !important'
                        },
                        "& input:-webkit-autofill": {
                            WebkitBoxShadow: "0 0 0 1000px transparent inset !important",
                            WebkitTextFillColor: "#FFF !important",
                            transition: "background-color 5000s ease-in-out 0s", // Delay transition to ensure the color change is not visible
                        },
                        "& input:-webkit-autofill:hover, & input:-webkit-autofill:focus, & input:-webkit-autofill:active": {
                            WebkitBoxShadow: "0 0 0 1000px transparent inset !important"
                        }
                    }
                }}
                value={value}
                name="value"
                onChange={e => setValue(e.target.value)}
                InputProps={{
                    style: {
                        borderRadius: "30px",
                        height: '2.5rem',
                        width: '20rem'
                    }
                }}
            />
        </div>
    </div>)
}

export default ValueComponent;
