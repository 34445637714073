import React from 'react';

const ConfirmModal = ({ isOpen, onClose, onConfirm, type }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
                <div> Are you sure you want to delete this {type}?</div>
                <div style={{ display: "flex", justifyContent: "center", gap: "20px",marginTop:'2rem'}}>
                    <button className="submitButton"
                        style={{
                            width: "5rem",
                            padding: "10px",
                            color: "#FFFFFF",
                            fontSize: "20px",
                            height: "40px",
                            borderRadius: "10px",
                            alignItems: "center",
                            cursor:"pointer"
                        }}
                        onClick={onConfirm}>Yes</button>
                    <button className="submitButton" 
                     style={{
                        width: "5rem",
                        padding: "10px",
                        color: "#FFFFFF",
                        fontSize: "20px",
                        height: "40px",
                        borderRadius: "10px",
                        alignItems: "center",
                        cursor:"pointer"
                    }}
                    onClick={onClose}>No</button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmModal;