import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { getLocalStorageValue } from '../util/util';
import '../css/pages.css'
import { AddIcon, RemoveIcon } from "../data/icons";
import StoryModal from '../components/story/StoryDetailModal';
import '../css/modal.css'
import AddModal from "../components/AddModal";
import EditIcon from '@mui/icons-material/Edit';
import EditModal from "../components/EditModal";
import * as Constants from '../data/constants';
import ConfirmModal from "../components/ConfirmDialog";

const SleepStories = (props) => {
    const token = getLocalStorageValue("adminPanelLoginData").token;
    const [categoryList, setCategoryList] = useState([])
    const heading = props.heading
    const disableSubmit = props.disableSubmit
    const setdisableSubmit = props.setdisableSubmit
    const [openConfirm, setOpenConfirm] = useState(false)

    const getCategory = async () => {
        await axios.get(Constants.HOST + "/api/get_category/",
            {
                headers: {
                    Authorization: `${token}`,
                },
                params: { type: props.selected }
            }
        )
            .then((response) => {
                console.log(response.data)
                setCategoryList(response.data.result)
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        getCategory()
    }, [disableSubmit])

    const [hoveredCategoryId, setHoveredCategoryId] = useState(null);
    const fileInputRef = useRef(null);

    const handleFileSelect = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append("image_path", file);
            formData.append("category_id", hoveredCategoryId);

            try {
                const response = await axios({
                    method: "PATCH",
                    url: Constants.HOST + "/api/category/",
                    headers: {
                        Authorization: `${token}`,
                        'Content-Type': 'multipart/form-data'
                    },
                    data: formData
                });
                console.log("Image updated:", response.data);
                getCategory();
            } catch (err) {
                console.error("Failed to update category image:", err);
            }
        }
    };
    const [currentStory, setCurrentStory] = useState(null);
    const playStory = (story) => {
        if (currentStory && story.id === currentStory.id) {
            return;
        }
        setCurrentStory(story);
    };

    const closeModal = () => {
        setCurrentStory(null);
        getCategory()
    };
    const closeEditModal = () => {
        setEditStory(null);
        getCategory()
    };

    const closeAddModal = () => {
        setModalState({ show: false, type: '', categoryId: null, categoryName: '', reqd: [] });
    }
    const [modalState, setModalState] = useState({ show: false, type: '', categoryId: null, categoryName: '', reqd: [] });

    const openAddCategoryModal = () => {
        setModalState({ show: true, type: 'Category', categoryId: props.selected, categoryName: heading, reqd: ['name', 'image'] });
    };

    const openAddEpisodeModal = (storyId, storyName) => {
        setModalState({ show: true, type: 'Episode', categoryId: storyId, categoryName: storyName, reqd: ['name', 'image', 'song'] });
    };

    const openAddSongModal = (categoryId, categoryName) => {
        setModalState({ show: true, type: 'Story', categoryId: categoryId, categoryName: categoryName, reqd: ['name', 'image', 'description'] });
    };

    const [editStory, setEditStory] = useState(null);
    const openEditModal = (story) => {
        setCurrentStory(null);
        setEditStory(story)
    }

    const deleteCategory = async () => {
        await axios(
            {
                method: "DELETE",
                url: Constants.HOST + `/api/category/`,
                headers: {
                    Authorization: `${token}`
                },
                params: { category_id: toBeDeleted.cat_id }
            }
        )
            .then((response) => {
                console.log(response.data)
                getCategory()
                setOpenConfirm(false)
            })
            .catch((err) => {
                console.log(err);
            })
    }
    const handleChangedStatus = async (category_id, story_id) => {
        setOpenConfirm(true)
        setToBeDeleted({ type: '', cat_id: '', story_id: '' })
        try {
            const response = await axios.put(
                `${Constants.HOST}/api/stories/`,
                { status: true },
                {
                    headers: {
                        Authorization: `${token}`,
                    },
                    params: {
                        story_id: story_id,
                        category_id: category_id,
                    },
                }
            );
            console.log(response.data);
            getCategory();
            setOpenConfirm(false)
            setCurrentStory(null)
        } catch (error) {
            console.error("Failed to update story status:", error);
        }
    }
    const deleteStory = async () => {
        await axios(
            {
                method: "DELETE",
                url: Constants.HOST + `/api/stories/`,
                headers: {
                    Authorization: `${token}`
                },
                params: { category_id: toBeDeleted.cat_id, story_id: toBeDeleted.story_id }
            }
        )
            .then((response) => {
                console.log(response.data)
                getCategory()
                setOpenConfirm(false)
                setCurrentStory(null)
            })
            .catch((err) => {
                console.log(err);
            })
    }
    const [toBeDeleted, setToBeDeleted] = useState({ type: '', cat_id: '', story_id: '' });

    const handleDeleteStoryClick = (cat_id, story_id) => {
        setToBeDeleted({ type: 'story', cat_id: cat_id, story_id: story_id });
        setOpenConfirm(true);
    };

    const handleDeleteCategoryClick = (cat_id) => {
        setToBeDeleted({ type: 'category', cat_id: cat_id, story_id: '' });
        setOpenConfirm(true);
    };

    const closeDialog = () => {
        setOpenConfirm(false);
        setCurrentStory(null);
    };

    const CategoryItem = () => (
        <div className="categoryList">
            {categoryList.map((item) => (
                <div className="categoryItem">
                    <div key={item.id} className="subcategoryTitle">
                        <div className="imageContainer" onMouseEnter={() => setHoveredCategoryId(item.id)}
                            onMouseLeave={() => setHoveredCategoryId(null)}>
                            <img
                                src={`${Constants.HOST}/${item.image_path}`}
                                alt={item.name}
                                className="categoryImage"
                            />
                            {hoveredCategoryId === item.id && (
                                <div className="editIcon" onClick={() => fileInputRef.current.click()}>
                                    <EditIcon />
                                </div>
                            )}
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={handleFileSelect}
                                accept="image/*"
                            />
                        </div>
                        {item.name}
                        <div onClick={() => openAddSongModal(item.id, item.name)}>
                            <AddIcon />
                        </div>
                        <div onClick={() => handleDeleteCategoryClick(item.id)}>
                            <RemoveIcon />
                        </div>
                    </div>
                    <div className="songGrid">
                        {item.stories && item.stories.map((story, index) => (
                            <div key={index} className="songItem">
                                <div className="storyContainer" onClick={() => story.status && playStory(story)}  style={{opacity: story.status ? 1:0.5}}>
                                    {(story.status === false) ? (
                                        <div className="removeContainer" onClick={() => handleChangedStatus(item.id, story.id)}>
                                            <AddIcon />
                                        </div>
                                    ) : (
                                        <div className="removeContainer" onClick={() => handleDeleteStoryClick(item.id, story.id)}>
                                            <RemoveIcon />
                                        </div>
                                    )}
                                    <div className="imageStory">
                                        <div className="imageContainer">
                                            <img
                                                src={`${Constants.HOST}/${story.image_path}`}
                                                alt={story.name}
                                                height='230px'
                                                width='250px'
                                            />
                                        </div>
                                        <div className="storyTitle">
                                            <div>{story.name}</div>
                                            <div className="storyDetails">
                                                <div>{`${story.listeners} listeners`}</div>
                                                <div>{`${story.no_of_episodes} episodes`}</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );

    return <div className="type">
        <div className="categoryTitle">
            <div>{heading}</div>
            <div onClick={openAddCategoryModal}><AddIcon /></div>
        </div>
        <CategoryItem />
        {modalState.show && (
            <AddModal isOpen={modalState.show} onClose={closeAddModal} type={modalState.type} category={modalState.categoryId} categoryName={modalState.categoryName} reqd={modalState.reqd} disableSubmit={disableSubmit} setdisableSubmit={setdisableSubmit} />
        )}
        {!openConfirm && currentStory && (
            <StoryModal isOpen={!!currentStory} onClose={closeModal} story={currentStory} openAddEpisodeModal={openAddEpisodeModal} openEditModal={openEditModal}>
            </StoryModal>
        )}
        {editStory && (
            <EditModal type={'Story'} isOpen={!!editStory} onClose={closeEditModal} story={editStory} reqd={['name', 'image', 'description']} disableSubmit={disableSubmit} setdisableSubmit={setdisableSubmit} ></EditModal>
        )}
        {openConfirm && (toBeDeleted.cat_id!='' || toBeDeleted.story_id!='') &&
            <ConfirmModal
                isOpen={openConfirm}
                onClose={closeDialog}
                onConfirm={toBeDeleted.type === "story" ? deleteStory : deleteCategory}
                type={toBeDeleted.type}>
            </ConfirmModal>
        }
    </div>
}

export default SleepStories;