import React from 'react'
import { BrowserRouter, Route, Routes, Navigate,NavLink } from 'react-router-dom';
import PageNotFound from '../pages/pageNotFound'
import AdminPage from '../pages/adminPage';

export const Router = () => {
    return (
        <div>
            <BrowserRouter>
                 <div id='main'>
                    <Routes>
                        <Route exact path="/" 
                            element={<Navigate replace to ="/dashboard" element={<AdminPage/>}></Navigate>  }> 
                        </Route>
                        <Route exact key="/dashboard" path="/dashboard" element={<AdminPage/>}></Route>
                        <Route path='*' exact={true} element={<PageNotFound/>} ></Route>
                    </Routes>
                </div>
            </BrowserRouter>
        </div >
    )
}


export default Router;
