import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { getLocalStorageValue } from '../../util/util';
import '../../css/pages.css'
import '../../css/modal.css'
import '../../css/login.css'
import { AddIcon } from "../../data/icons";
import EditIcon from '@mui/icons-material/Edit';
import * as Constants from '../../data/constants';
import EpisodeList from "./EpisodeList";
import ConfirmModal from "../ConfirmDialog";

const StoryModal = ({ isOpen, onClose, story, openAddEpisodeModal, openEditModal }) => {
    const token = getLocalStorageValue("adminPanelLoginData").token;
    const [storyData, setStoryData] = useState(null);
    const [openConfirm, setOpenConfirm] = useState(false)

    const getStoryDetail = async () => {
        await axios.get(Constants.HOST+ "/api/get_story/",
            {
                headers: {
                    Authorization: `${token}`,
                },
                params: { story_id: story.id }
            }
        )
            .then((response) => {
                setStoryData(response.data.result[0])
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        getStoryDetail()
    }, [])
    const [currentEpisode, setCurrentEpisode] = useState(null);
    const audioRef = useRef(null);

    useEffect(() => {
        if (currentEpisode) {
            if (audioRef.current) {
                audioRef.current.src = `${Constants.HOST}/${currentEpisode.episode_path}`;
                audioRef.current.play().catch(e => console.error('Error playing audio:', e));
            }
        }
    }, [currentEpisode]);

    const playEpisode = (episode) => {
        if (currentEpisode && episode.id === currentEpisode.id) {
            return;
        }
        setCurrentEpisode(episode);
    };

    console.log(currentEpisode)

    const [hoveredCategoryId, setHoveredCategoryId] = useState(null);
    const fileInputRef = useRef(null);

    const handleFileSelect = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append("image_path", file);
            formData.append("episode_id", hoveredCategoryId);

            try {
                const response = await axios({
                    method: "PATCH",
                    url: Constants.HOST+  "/api/episodes/",
                    headers: {
                        Authorization: `${token}`,
                        'Content-Type': 'multipart/form-data'
                    },
                    data: formData
                });
                console.log("Image updated:", response.data);
                getStoryDetail();
            } catch (err) {
                console.error("Failed to update category image:", err);
            }
        }
    }
    const [toBeDeleted, setToBeDeleted] = useState({ type: '', episode_id: '' });


    const handleDeleteClick = (episode_id) => {
        setToBeDeleted({ type: 'episode', episode_id: episode_id });
        setOpenConfirm(true);
    };

    const closeDialog = () => {
        setOpenConfirm(false);
    };


    const deleteEpisode = async () => {
        await axios(
            {
                method: "DELETE",
                url: Constants.HOST+ `/api/episodes/`,
                headers: {
                    Authorization: `${token}`
                },
                params: { episode_id: toBeDeleted.episode_id }
            }
        )
            .then((response) => {
                console.log(response.data)
                getStoryDetail();
                setOpenConfirm(false)
            })
            .catch((err) => {
                console.log(err);
            })
    }

   

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
                <button className="modal-close-button" onClick={onClose}>&times;</button>
                <div className="imageStory">
                    <h2 style={{ alignSelf: "center" }}>{story.name}</h2>
                    <h3>About The Story</h3>
                    <div className="storyDesc">{story.description}</div>
                    <button style={{
                        background: "transparent",
                        color: "#ffffff", height: "40px", border: "1px solid #FFFFFF", margintop: "10px", borderRadius: "5px", marginTop: '10px'
                    }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px', height: '40px', cursor: 'pointer' }}
                            onClick={() => openEditModal(story)}>
                            <h3>Edit Story</h3>
                            <div>
                                <EditIcon />
                            </div>
                        </div>
                    </button>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <h3>Explore Episodes</h3>
                        <div onClick={() => openAddEpisodeModal(story.id, story.name)}>
                            <AddIcon />
                        </div>
                    </div>
                    {storyData &&
                    <EpisodeList 
                    storyData={storyData}
                    playEpisode={playEpisode}
                    currentEpisode={currentEpisode}
                    hoveredCategoryId={hoveredCategoryId}
                    setHoveredCategoryId={setHoveredCategoryId}
                    fileInputRef={fileInputRef}
                    handleFileSelect={handleFileSelect}
                    deleteEpisode={handleDeleteClick}
                    audioRef={audioRef}/>}
                     {openConfirm &&
                    <ConfirmModal
                        isOpen={openConfirm}
                        onClose={closeDialog}
                        onConfirm={deleteEpisode}
                        type={toBeDeleted.type}>
                    </ConfirmModal>
                    }
                </div>
            </div>
        </div>
    );
};

export default StoryModal;