import React, { useRef, useState } from "react";
import axios from "axios";
import * as Constants from '../../data/constants'
import { CircularProgress } from '@mui/material';
import { UploadIcon, AddCategoryIcon } from "../../data/icons";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getLocalStorageValue } from '../../util/util';
import { Category } from "@mui/icons-material";

const BianuralBeatsInfo = ({ isOpen, onClose, categoryInfo}) => {
    const token = getLocalStorageValue("adminPanelLoginData").token;
    const [desc, setDesc] = useState()
    const [colorCodes, setColorCodes] = useState()
    const [image, setImage] = useState()
    const [disableSubmit, setdisableSubmit] = useState(false)
    const [selected,setSelected] = useState(0)
    const [waveInfo,setWaveInfo] = useState(categoryInfo[0])

  
    const [editInfo, setEditInfo] = useState(false)

    const handleEdit = () => {
        setEditInfo(true);
    }

    const handleNext = () => {
        const nextIndex = selected + 1;
        if (nextIndex < categoryInfo.length) {
            setSelected(nextIndex);
            setWaveInfo(categoryInfo[nextIndex]);
        }
    };

    const handlePrev = () => {
        const prevIndex = selected - 1;
        if (prevIndex >= 0) {
            setSelected(prevIndex);
            setWaveInfo(categoryInfo[prevIndex]);
        }
    };

    const handleDescChange = (e) => {
        setDesc(e.target.value);
    };

    const handleColorCodesChange = (e) => {
        setColorCodes(e.target.value);
    };

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setdisableSubmit(true);

        const formData = new FormData();
        formData.append('description', desc);
        formData.append('color_codes', colorCodes);
        if (image) {
            formData.append('image', image);
        }

        try {
            const response = await axios.put(`${Constants.HOST}/api/frequency_info/`, formData,
                {
                    headers: {
                        Authorization: `${token}`,
                    },
                }
            );
            console.log('Update successful:', response.data);
            setdisableSubmit(false);
            onClose();
        } catch (error) {
            console.error('Update failed:', error);
            setdisableSubmit(false);
        }
    };

    var colorList = waveInfo.color_codes.map((code) => '#' + code);
    var colors = colorList.join(', ')
    var description = waveInfo.description


    return <div className="modal-overlay" onClick={onClose}>
        <div className="frequencyInfo" style={{ backgroundImage: `linear-gradient(45deg, ${colors})`, height:'66%'}} onClick={e => e.stopPropagation()}>
            {/* {editInfo && <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <div onClick={() => setEditInfo(false)} style={{ display: 'flex', cursor: 'pointer' }}>
                    <ArrowBackIcon />
                </div>
                <form className="addModal" style={{ height: '100%' }} onSubmit={handleSubmit}>
                    <div style={{ width: '100%', display: 'flex', padding: '20px', alignItems: 'center' }}>
                        <label style={{ display: 'flex', fontSize: '18px', fontWeight: '400', paddingRight: '1rem',textAlign:"left" }}> Add Description</label>
                        <textarea rows='5' cols='30' value={desc} onChange={handleDescChange}
                            style={{
                                width: '100%',
                                padding: '8px',
                                fontSize: '14px',
                                borderRadius: '5px',
                                border: '1px solid #ccc'
                            }} />
                    </div>
                    <div style={{ width: '100%', display: 'flex', padding: '20px', alignItems: 'center' }}>
                        <label style={{ display: 'flex', fontSize: '18px', fontWeight: '400', paddingRight: '0.5rem',textAlign:"left"  }}>New Image</label>
                        <input type='file' onChange={handleImageChange} accept="image/*" id="file"
                            style={{
                                padding: '10px',
                                fontSize: '16px',
                                borderRadius: '5px'
                            }} />
                    </div>
                    <div style={{ width: '100%', display: 'flex', padding: '20px', alignItems: 'center' }}>
                        <label style={{ display: 'flex', fontSize: '18px', fontWeight: '400', paddingRight: '2rem',textAlign:"left"  }}>Color Codes</label>
                        <textarea rows='2' cols='30' value={colorCodes} onChange={handleColorCodesChange}
                            style={{
                                width: '100%',
                                padding: '8px',
                                fontSize: '14px',
                                borderRadius: '5px',
                                border: '1px solid #ccc'
                            }} />
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '30px' }}>
                        <button className="cancelButton" onClick={onClose}>
                            <div>Cancel</div>
                        </button>
                        <button className="submitButton1" type="submit" disabled={colorCodes === "" || desc === ""}>

                            {disableSubmit && <CircularProgress sx={{ color: 'white' }} size={12} />}
                            {!disableSubmit &&
                                <div style={{ display: 'flex', gap: '10px' }}>
                                    <div>Edit</div>
                                    <AddCategoryIcon />
                                </div>}
                        </button>
                    </div>

                </form>
            </div>
            } */}
            {!editInfo && <div style={{display:'flex',flexDirection:'column', height:'100%'}}>
                <div className='bianuralValue' style={{ WebkitTextStrokeColor: `${colorList[0]}`, WebkitTextStrokeWidth: '1px',fontSize:'38px',width:'85%',textAlign:'center',top:'10%',left:'10%'}}>
                           Why to use {waveInfo.name || "Bianural Beats"} ?
                        </div>
                <div className="gradientImageContainer" style={{height:'70%'}}>
                    <div className='cardContainer' style={{ background: `linear-gradient(45deg, ${colors})` }}>

                        <img src={`${Constants.HOST}/${waveInfo.image_path}`}
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'block'
                            }}></img>
                        <div className="gradientOverlay" style={{ background: `linear-gradient(45deg, ${colors})` }}></div>
                     
                    </div>

                </div>
                <div className="description">
                    {waveInfo.quote}
                </div>
                <div className="modalButtons">
                    <button className="buttonStyle" onClick={() => selected>0? handlePrev():onClose()}>  {selected>0? 'PREV':'DISMISS'}</button>
                    <button className="buttonStyle" onClick={() => selected<categoryInfo.length-1? handleNext():onClose()}> {selected<categoryInfo.length-1? 'NEXT':'DISMISS'} </button>
                </div>
            </div>}
        </div>

    </div>
}

export default BianuralBeatsInfo