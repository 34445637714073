import React, { useRef, useState } from "react";
import axios from "axios";
import { getLocalStorageValue } from '../../util/util';
import '../../css/pages.css'
import '../../css/modal.css'
import '../../css/login.css'
import NameComponent from '../NameComponent';
import DescriptionComponent from '../DescriptionComponent';
import ImageComponent from '../ImageComponent';
import SongComponent from '../SongComponent';
import { UploadIcon, AddCategoryIcon } from "../../data/icons";
import { CircularProgress } from '@mui/material';
import * as Constants from '../../data/constants';

const EditEpisodeModal = ({ type, isOpen, onClose, episode, reqd, disableSubmit, setdisableSubmit }) => {
    const token = getLocalStorageValue("adminPanelLoginData").token;

    const [name, setName] = useState(episode.title);
    const [desc, setDesc] = useState(episode.description);
    const imageInputRef = useRef(null);
    const songInputRef = useRef(null);
    const [image, setImage] = useState();
    const [newSong, setNewSong] = useState();

    var image_path = episode.image_path ? episode.image_path.split('/') : ''
    var song_path = episode.episode_path ? episode.episode_path.split('/') : ''

    const [imageName, setimageName] = useState(image_path[image_path.length - 1]);
    const [newSongName, setNewSongName] = useState(song_path[song_path.length - 1]);
    const [epiNumber, setEpisodeNo] = useState(episode.episode_no)

    const handleFileInput = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImage(file)
            setimageName(file.name);
        }
    };

    const handleUploadClick = (event) => {
        event.preventDefault();
        imageInputRef.current.click();
    };

    const handleSongInput = (event) => {
        const song = event.target.files[0];
        if (song) {
            setNewSong(song)
            setNewSongName(song.name);
        }
    };

    const handleSongClick = (event) => {
        event.preventDefault();
        songInputRef.current.click();
    };



    const onEditEpisode = async (event) => {
        event.preventDefault();
        setdisableSubmit(true);
        const formData = new FormData()
        if (image != null) {
            formData.append("image_path", image)
        }
        formData.append("episode_id", episode.id)
        formData.append("title", name)
        formData.append("description", desc)
        formData.append("episode_no", epiNumber)
        if (newSong != null) {
            formData.append("episode_path", newSong)
        }
        await axios(
            {
                method: "PATCH",
                url: Constants.HOST + "/api/podepisodes/",
                headers: {
                    Authorization: `${token}`,
                    'Content-Type': 'multipart/form-data'
                },
                data: formData
            }
        )
            .then((response) => {
                setdisableSubmit(false)
                onClose()
                console.log(response.data)
            })
            .catch((err) => {
                setdisableSubmit(false)
                onClose()
                console.log(err);
            })
    }


    if (!isOpen) return null;

    return (<div className="modal-overlay" onClick={onClose}>
        <div className="modal-content" style={{ backgroundColor: '#0c0a0a', }} onClick={e => e.stopPropagation()}>
            <div>
                <button className="modal-close-button" onClick={onClose}>&times;</button>
            </div>
            <div style={{ padding: '2rem' }}>
                <div style={{ fontSize: '28px', fontWeight: '700' }}> {`Edit ${episode.title}`} </div>
            </div>

            <form style={{ width: '100%', display: "flex", justifyContent: 'flex-start', flexDirection: 'column' }} onSubmit={onEditEpisode}>
                {reqd.includes('episode_no') &&
                    <NameComponent name={epiNumber} setName={setEpisodeNo} type={'Episode'} />
                }
                {reqd.includes('name') &&
                    <NameComponent name={name} setName={setName} type={'Edit Story'} />
                }
                <div style={{ display: reqd.includes('value') ? 'flex' : 'block', gap: '5rem' }}>
                    {reqd.includes('image') &&
                        <ImageComponent fileInputRef={imageInputRef} fileName={imageName} handleFileInput={handleFileInput} handleUploadClick={handleUploadClick} type={type} />
                    }
                    {reqd.includes('song') &&
                        <SongComponent fileInputRef={songInputRef} fileName={newSongName} handleFileInput={handleSongInput} handleUploadClick={handleSongClick} type={type} />
                    }
                </div>
                {reqd.includes('description') &&
                    <DescriptionComponent desc={desc} setDesc={setDesc} type={type} />
                }
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '5px' }}>
                    <button className="cancelButton" onClick={onClose}>
                        <div>Cancel</div>
                    </button>
                    <button className="submitButton1" type="submit" disabled={name === "" || desc === "" || epiNumber == ""}>

                        {disableSubmit && <CircularProgress sx={{ color: 'white' }} size={12} />}
                        {!disableSubmit &&
                            <div style={{ display: 'flex', gap: '10px' }}>
                                <div>Edit</div>
                                <AddCategoryIcon />
                            </div>}
                    </button>
                </div>
            </form>
        </div>
    </div>)

}

export default EditEpisodeModal;