import React, { useRef, useState } from "react";
import axios from "axios";
import { getLocalStorageValue } from '../util/util';
import '../css/pages.css'
import '../css/modal.css'
import '../css/login.css'
import NameComponent from './NameComponent';
import DescriptionComponent from './DescriptionComponent';
import ImageComponent from './ImageComponent';
import SongComponent from './SongComponent';
import { AddCategoryIcon } from "../data/icons";
import { CircularProgress} from '@mui/material';
import * as Constants from '../data/constants';
import ValueComponent from "./ValueComponent";

const AddModal = ({ isOpen, onClose, type, category, categoryName, reqd,disableSubmit,setdisableSubmit }) => {
    const token = getLocalStorageValue("adminPanelLoginData").token;

    const [name, setName] = useState('');
    const [hostname, setHostName] = useState('');
    const [genre, setGenres] = useState('');
    const [episodeNo, setEpisodeNo] = useState();
    const [value, setValue] = useState('');
    const [colorCodes, setColorCodes] = useState([]);
    const [desc,setDesc] = useState('');
    const imageInputRef = useRef(null);
    const songInputRef = useRef(null);
    const [image, setImage] = useState();
    const [song, setSong] = useState();
    const [imageName, setimageName] = useState('');
    const [songName, setSongName] = useState('');

    const handleFileInput = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImage(file)
            setimageName(file.name);
        }
    };

    const handleUploadClick = (event) => {
        event.preventDefault();
        imageInputRef.current.click();
    };
    const handleSongInput = (event) => {
        const song = event.target.files[0];
        if (song) {
            setSong(song)
            setSongName(song.name);
        }
    };

    const handleSongClick = (event) => {
        event.preventDefault();
        songInputRef.current.click();
    };

    const onSubmitCategory = async (event) => {
        event.preventDefault()
        setdisableSubmit(true)
        await axios(
            {
                method: "POST",
                url: Constants.HOST+ "/api/category/",
                headers: {
                    Authorization: `${token}`,
                    'Content-Type': 'multipart/form-data'
                },
                data: { name: name, image_path: image, type: category, status: true,quote: desc }
            }
        )
            .then((response) => {
                // if(response.status == 200){
                setdisableSubmit(false)
                onClose()
                // }
                console.log(response.data)
            })
            .catch((err) => {
                setdisableSubmit(false)
                onClose()
                console.log(err);
            })
    }

    const onSubmitSong = async (event) => {
        event.preventDefault()
        setdisableSubmit(true)
        await axios(
            {
                method: "POST",
                url:Constants.HOST+ "/api/audios/",
                headers: {
                    Authorization: `${token}`,
                    'Content-Type': 'multipart/form-data'
                },
                data: { name: name, audio_path: song, category: category, status: true,image_path: image,value:value,color_codes:colorCodes,description:desc }
            }
        )
            .then((response) => {
                setdisableSubmit(false)
                onClose()
                console.log(response.data)
            })
            .catch((err) => {
                setdisableSubmit(false)
                onClose()
                console.log(err);
            })
    }
    const onSubmitStory = async (event) => {
        event.preventDefault()
        setdisableSubmit(true)
        await axios(
            {
                method: "POST",
                url: Constants.HOST+ "/api/stories/",
                headers: {
                    Authorization: `${token}`,
                    'Content-Type': 'multipart/form-data'
                },
                data: { name: name, image_path: image, description:desc,category: category, status: true }
            }
        )
            .then((response) => {
                setdisableSubmit(false)
                onClose()
                console.log(response.data)
            })
            .catch((err) => {
                setdisableSubmit(false)
                onClose()
                console.log(err);
            })
    }

    const onSubmitEpisode=async(event)=>{
        event.preventDefault();
        setdisableSubmit(true);
        await axios(
            {
                method: "POST",
                url: Constants.HOST+ "/api/episodes/",
                headers: {
                    Authorization: `${token}`,
                    'Content-Type': 'multipart/form-data'
                },
                data: { episode_no: name, image_path: image, episode_path:song,story:category, status: true }
            }
        )
            .then((response) => {
                setdisableSubmit(false)
                onClose()
                console.log(response.data)
            })
            .catch((err) => {
                setdisableSubmit(false)
                onClose()
                console.log(err);
            })
    }

    
    const onSubmitPodEpisode=async(event)=>{
        event.preventDefault();
        setdisableSubmit(true);
        await axios(
            {
                method: "POST",
                url: Constants.HOST+ "/api/podepisodes/",
                headers: {
                    Authorization: `${token}`,
                    'Content-Type': 'multipart/form-data'
                },
                data: {episode_no:episodeNo,title: name,image_path: image, episode_path:song,podcast:category, status: true,description:desc}
            }
        )
            .then((response) => {
                setdisableSubmit(false)
                onClose()
                console.log(response.data)
            })
            .catch((err) => {
                setdisableSubmit(false)
                onClose()
                console.log(err);
            })
    }

    const onSubmitPodcast = async (event) => {
        event.preventDefault()
        setdisableSubmit(true)
        await axios(
            {
                method: "POST",
                url: Constants.HOST+ "/api/podcast/",
                headers: {
                    Authorization: `${token}`,
                    'Content-Type': 'multipart/form-data'
                },
                data: { name: name, image_path: image, description:desc,hostname:hostname, genre:genre,status: true }
            }
        )
            .then((response) => {
                setdisableSubmit(false)
                onClose()
                console.log(response.data)
            })
            .catch((err) => {
                setdisableSubmit(false)
                onClose()
                console.log(err);
            })
    }



    if (!isOpen) return null;

    return (<div className="extra" onClick={onClose}>
        <div className="modal-content" style={{ backgroundColor: '#0c0a0a', }} onClick={e => e.stopPropagation()}>
            <div>
                <button className="modal-close-button" onClick={onClose}>&times;</button>
            </div>
            <div style={{ padding: '2rem' }}>
                <div style={{ fontSize: '28px', fontWeight: '700' }}> {categoryName} </div>
                <div style={{ fontSize: '20px', fontWeight: '400' }}>{` ${type=='Podcast' ? 'Add a New Podcast' : `Add a New ${type} to ${categoryName} ${type === 'Episode' ? 'Story' : type==='Podcast Episode' ? '': type === 'Category' ? 'Playlist' : 'Category'}`}`}</div>
            </div>

            <form style={{ width: '100%', display: "flex", justifyContent: 'flex-start', flexDirection: 'column' }} onSubmit={type==='Podcast' ? onSubmitPodcast : type==='Podcast Episode' ? onSubmitPodEpisode:  type==='Episode'? onSubmitEpisode:type==='Category'?onSubmitCategory:type==='Song'?onSubmitSong:onSubmitStory}>
                {reqd.includes('name') &&
                    <NameComponent name={name} setName={setName} type={type}/>
                }
                {reqd.includes('episodeNo') &&
                    <NameComponent name={episodeNo} setName={setEpisodeNo} type={'Episode'}/>
                }
                {reqd.includes('host') &&
                    <NameComponent name={hostname} setName={setHostName} type={'host'}/>
                }
                {reqd.includes('value') &&
                    <ValueComponent value={value} setValue={setValue} type={type}/>
                }
                <div style={{display:reqd.includes('value')? 'flex':'block',gap: '5rem'}}>
                {reqd.includes('image') &&
                    <ImageComponent fileInputRef={imageInputRef} fileName={imageName} handleFileInput={handleFileInput} handleUploadClick={handleUploadClick} />
                }
                {reqd.includes('song') &&
                    <SongComponent fileInputRef={songInputRef} fileName={songName} handleFileInput={handleSongInput} handleUploadClick={handleSongClick} type={type}/>
                }
                </div>
                {reqd.includes('description') &&
                    <DescriptionComponent desc={desc} setDesc={setDesc} type={type}/>
                }
                {reqd.includes('color_codes') &&
                    <DescriptionComponent desc={colorCodes} setDesc={setColorCodes} type={'Brainwaves'}/>
                }
                {reqd.includes('genre') &&
                    <DescriptionComponent desc={genre} setDesc={setGenres} type={'genre'}/>
                }
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '5px' }}>
                    <button className="cancelButton" onClick={onClose}>
                        <div>Cancel</div>
                    </button>
                    <button className="submitButton1" type="submit" disabled={(image === null && song===null) || name === ""}>
                        
                        {disableSubmit && <CircularProgress sx={{ color: 'white' }} size={12} />}
                        {!disableSubmit && 
                        <div style={{ display: 'flex', gap: '10px' }}>
                        <div>Add</div>
                        <AddCategoryIcon />
                        </div>}
                    </button>
                </div>
            </form>
        </div>
    </div>)

}

export default AddModal;