import React from 'react';

const DescriptionComponent = ({ type,desc, setDesc }) => {
    return (<div>
        <div style={{ display: 'flex', fontSize: '18px', fontWeight: '400', padding: '1rem' }}>{type=== 'genre'? 'Genres': type === 'Brainwaves' ? 'Color Codes':type==='Story'?'Description':'Description'}</div>
        <div style={{ display: 'flex', padding: '0.5rem' }}>
            <textarea
                value={desc}
                name="description"
                placeholder='Type here...'
                rows={4}
                cols={40}
                style={{backgroundColor:'transparent',color:'#F3F3F3',fontSize:'16px',borderRadius:'10px'}}
                onChange={e => setDesc(e.target.value)} />
        </div>
    </div>)
}

export default DescriptionComponent;
