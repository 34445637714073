import React, { useEffect } from 'react';
import Login from '../pages/login';
import { getLocalStorageValue } from '../util/util';
import Router from './router';
function getToken() {
    const tokenString = getLocalStorageValue('adminPanelLoginData');
    return tokenString?.token
}

export default function CheckLogin(){
    const token = getToken();
    const lastLoginTime = parseInt(localStorage.getItem("adminloginTime")) || 0;
    const expireTime = 1000*60*60*12;
    const [loginExpired,setLoginExpired] = React.useState(false);
   
    if(!((new Date().getTime() - lastLoginTime) > expireTime) && loginExpired){
        setLoginExpired(true)
    }

    const loginCheck = () => {
        if (!token|| (new Date().getTime() - lastLoginTime) > expireTime){
            setLoginExpired(true)
        }
    }


    if (loginExpired || !token || (new Date().getTime() - lastLoginTime) > expireTime){
        return <>
                <Login/>
                </>
    }
    else{
    return <div className="main" onClick={loginCheck}>
        <Router/>
            </div>
    }
}