import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { getLocalStorageValue } from '../util/util';
import '../css/pages.css'
import { AddIcon, RemoveIcon } from "../data/icons";
import Modal from '../components/PlayModal';
import '../css/modal.css'
import AddModal from "../components/AddModal";
import EditIcon from '@mui/icons-material/Edit';
import * as Constants from '../data/constants';
import ConfirmModal from "../components/ConfirmDialog";

const DailyMood = (props) => {
    const token = getLocalStorageValue("adminPanelLoginData").token;
    const [categoryList, setCategoryList] = useState([])
    const [openConfirm, setOpenConfirm] = useState(false)
    const heading = props.heading
    const disableSubmit=props.disableSubmit
    const setdisableSubmit=props.setdisableSubmit
    const getCategory = async () => {
        await axios.get(Constants.HOST+  "/api/get_category/",
            {
                headers: {
                    Authorization: `${token}`,
                },
                params: { type: props.selected }
            }
        )
            .then((response) => {
                console.log(response.data)
                setCategoryList(response.data.result)
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        getCategory()
    }, [disableSubmit])

    const [currentSong, setCurrentSong] = useState(null);

    const audioRef = useRef(null);

    useEffect(() => {
        if (currentSong) {
            if (audioRef.current) {
                audioRef.current.src = `${Constants.HOST}/${currentSong.audio_path}`;
                audioRef.current.play().catch(e => console.error('Error playing audio:', e));
            }
        }
    }, [currentSong]);

    const playSong = (song) => {
        if (currentSong && song.id === currentSong.id) {
            return;
        }
        setCurrentSong(song);
    };

    const closeModal = () => {
        setCurrentSong(null);
        if (audioRef.current) {
            audioRef.current.pause();
        }
    };
    const [hoveredCategoryId, setHoveredCategoryId] = useState(null);
    const fileInputRef = useRef(null);

    const handleFileSelect = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append("image_path", file);
            formData.append("category_id", hoveredCategoryId);

            try {
                const response = await axios({
                    method: "PATCH",
                    url: Constants.HOST+  "/api/category/",
                    headers: {
                        Authorization: `${token}`,
                        'Content-Type': 'multipart/form-data'
                    },
                    data: formData
                });
                console.log("Image updated:", response.data);
                getCategory();
            } catch (err) {
                console.error("Failed to update category image:", err);
            }
        }
    };

    const closeAddModal = () => {
        setModalState({ show: false, type: '', categoryId: null, categoryName: '', reqd: [] });
    }
    const [modalState, setModalState] = useState({ show: false, type: '', categoryId: null, categoryName: '', reqd: [] });

    const openAddCategoryModal = () => {
        setModalState({ show: true, type: 'Category', categoryId: props.selected, categoryName: heading, reqd: ['name', 'image'] });
    };

    const openAddSongModal = (categoryId, categoryName) => {
        setModalState({ show: true, type: 'Song', categoryId: categoryId, categoryName: categoryName, reqd: ['name', 'song'] });
    };

    const deleteCategory = async () => {
        await axios(
            {
                method: "DELETE",
                url: Constants.HOST+ `/api/category/`,
                headers: {
                    Authorization: `${token}`
                },
                params: { category_id: toBeDeleted.cat_id }
            }
        )
            .then((response) => {
                console.log(response.data)
                getCategory()
                setOpenConfirm(false);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const deleteSong = async () => {
        await axios(
            {
                method: "DELETE",
                url: Constants.HOST + `/api/audios/`,
                headers: {
                    Authorization: `${token}`
                },
                params: { category_id: toBeDeleted.cat_id, audio_id: toBeDeleted.song_id }
            }
        )
            .then((response) => {
                getCategory()
                setOpenConfirm(false);
            })
            .catch((err) => {
                console.log(err);
            })
    }
    const [toBeDeleted, setToBeDeleted] = useState({ type: '', cat_id: '', song_id: '' });
    const handleDeleteSongClick = (cat_id, song_id) => {
        setToBeDeleted({ type: 'song', cat_id: cat_id, song_id: song_id });
        setOpenConfirm(true);
    };

    const handleDeleteCategoryClick = (cat_id) => {
        setToBeDeleted({ type: 'category', cat_id: cat_id, song_id: '' });
        setOpenConfirm(true);
    };

    const closeDialog = () => {
        setOpenConfirm(false);
    };

    const handleChangedStatus = async (song_id,category_id) => {
        try {
            const response = await axios.put(
                `${Constants.HOST}/api/audios/`,
                { status: true }, // Set the status to true
                {
                    headers: {
                        Authorization: `${token}`,
                    },
                    params: {
                        audio_id: song_id,
                        category_id:category_id ,
                    },
                }
            );
            console.log(response.data);
            // Optionally, you can update the local state or refetch the category list
            getCategory();
        } catch (error) {
            console.error("Failed to update song status:", error);
        }
    };
    

    const CategoryItem = () => (
        <div className="categoryList">
            {categoryList.map((item) => (
                <div className="categoryItem">
                    <div key={item.id} className="subcategoryTitle">
                        <div className="imageContainer" onMouseEnter={() => setHoveredCategoryId(item.id)}
                            onMouseLeave={() => setHoveredCategoryId(null)}>
                            <img
                                src={`${Constants.HOST}/${item.image_path}`}
                                alt={item.name}
                                className="categoryImage"
                            />
                            {hoveredCategoryId === item.id && (
                                <div className="editIcon" onClick={() => fileInputRef.current.click()}>
                                    <EditIcon />
                                </div>
                            )}
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={handleFileSelect}
                                accept="image/*"
                            />
                        </div>

                        {item.name}
                        <div onClick={() => openAddSongModal(item.id, item.name)}>
                            <AddIcon />
                        </div>
                        <div onClick={() => handleDeleteCategoryClick(item.id)}>
                            <RemoveIcon />
                        </div>
                    </div>
                    <div className="songGrid">
                        {item.songs && item.songs.map((song, index) => (
                            <div key={index} className="songItem">
                                <div className="songTitle">
                                    {(song.status===false)?(
                                        <div onClick={() => handleChangedStatus(song.id,item.id)}>
                                            <AddIcon />
                                        </div>
                                    ):(
                                    <div onClick={() => handleDeleteSongClick(item.id, song.id)}>
                                        <RemoveIcon />
                                    </div>
                                    )}
                                    <div onClick={() => playSong(song)} style={{display:'flex', alignItems:'center',gap:'10px'}}>
                                        <div className={`${song.status===false?"statusFalseColor":""}`}> {song.name} </div>
                                        <div className={`${song.status===false?"statusFalseColor":""}`}> {`(${song.duration})`} </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );

    return <div className="type">
        <div className="categoryTitle">
            <div>{heading}</div>
            <div onClick={openAddCategoryModal}><AddIcon /></div>
        </div>
        <CategoryItem />
        {currentSong && (
            <Modal isOpen={!!currentSong} onClose={closeModal}>
                <h2>{currentSong.name}</h2>
                <audio ref={audioRef} controls autoPlay>
                    Your browser does not support the audio element.
                </audio>
            </Modal>
        )}
        {modalState.show && (
            <AddModal isOpen={modalState.show} onClose={closeAddModal} type={modalState.type} category={modalState.categoryId} categoryName={modalState.categoryName} reqd={modalState.reqd} disableSubmit={disableSubmit} setdisableSubmit={setdisableSubmit} />
        )}
        {openConfirm &&
            <ConfirmModal
                isOpen={openConfirm}
                onClose={closeDialog}
                onConfirm={toBeDeleted.type === "song" ? deleteSong : deleteCategory}
                type={toBeDeleted.type}>
            </ConfirmModal>
        }
    </div>
}

export default DailyMood;