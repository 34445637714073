import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { getLocalStorageValue } from '../../util/util';
import '../../css/pages.css'
import '../../css/modal.css'
import '../../css/login.css'
import { AddIcon, RemoveIcon } from "../../data/icons";
import EditIcon from '@mui/icons-material/Edit';
import * as Constants from '../../data/constants';



const EpisodeList = ({ storyData, setHoveredCategoryId, hoveredCategoryId, fileInputRef, handleFileSelect, playEpisode,
    currentEpisode, deleteEpisode, audioRef, onEditEpisode }) => {
    const data = storyData.episodes
    return <div className="categoryList" style={{ gap: "3rem", maxHeight: "12em", overflowY: "auto" }}>
        {data.map((item) => (
            <div key={item.id} className="categoryItem">
                <div className="subcategoryTitle">
                    <div className="imageContainer" onMouseEnter={() => setHoveredCategoryId(item.id)}
                        onMouseLeave={() => setHoveredCategoryId(null)}>
                        <img
                            src={`${Constants.HOST}/${item.image_path}`}
                            alt={item.name}
                            className="categoryImage"
                        />
                        {!(item.title && item.description) && hoveredCategoryId === item.id && (
                            <div className="editIcon" onClick={() => fileInputRef.current.click()}>
                                <EditIcon />
                            </div>
                        )}
                        <input
                            type="file"
                            ref={fileInputRef}
                            placehodler={'Add Quote...'}
                            style={{ display: 'none' }}
                            onChange={handleFileSelect}
                            accept="image/*"
                        />
                    </div>
                    <div onClick={() => playEpisode(item)} style={{ display: "flex", flexDirection: 'column', alignItems: "flex-start", gap: '5px', cursor: 'pointer' }}>
                        {'Episode ' + item.episode_no}
                        <div>{item.title}</div>
                        <div style={{ fontSize: '14px' }}>{item.description}</div>
                        {currentEpisode && currentEpisode.id === item.id &&
                            <audio ref={audioRef} controls autoPlay>
                                Your browser does not support the audio element.
                            </audio>
                        }
                    </div>
                    <div onClick={() => deleteEpisode(item.id)}><RemoveIcon /></div>
                    {item.title && item.description && <div onClick={()=>onEditEpisode(item)}><EditIcon /></div>}
                </div>
            </div>
        ))}
    </div>
}

export default EpisodeList;